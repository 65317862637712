<template>
  <component
    :is="uiStore.isMobile ? SideScroll:'div'"
    v-if="brands.length > 0"
  >
    <div class="flex desk:flex-wrap mobOnly:pl-12">
      <nuxt-link
        v-for="brand in brands"
        :key="brand.brandCode"
        :to="`/${brand.brandLink}`"
        class="btn mr-6 desk:mb-6"
      >
        {{ brand.brandName }}
      </nuxt-link>

      <div class="sideScrollRightPad desk:hidden" />
    </div>
  </component>
</template>
<script setup lang="ts">
import { useUserStore } from '~/store/user';
import SideScroll from '~/components/globals/SideScroll.vue';
import { useUiStore } from '~/store/ui';
import { BrandItem, useGlobalContentStore } from '~/store/globalContent';
const globalContent = useGlobalContentStore();

const uiStore = useUiStore();

const userStore = useUserStore();

const brands = computed<BrandItem[]>(()=> {
  if (!globalContent.brandListLoaded) {
    return [];
  }

  // Removes favorites without name,
  // this is probably because the brandpage has been deleted
  return userStore.userProfile.favoriteBrands.map((m) => {
    return {
      brandCode: m,
      brandName: globalContent.getBrandName(m),
      brandFlag: globalContent.getBrandFlag(m),
      brandLink: globalContent.getBrandLink(m),
    };
  }).filter((f) => f.brandName !== '');
});

// Makes sure brands are loaded
onMounted(()=> {
  globalContent.loadBrands();
});

</script>
